( function( $, window ) {
	'use strict';

	$( function() {
		var $waypoint = $( '.js-waypoint' );
		var waypointVisible = 'is-visible';

		if ( window.matchMedia( '(min-width: 768px)' ).matches ) {
			$waypoint.each( function() {
				$( this ).waypoint( {
					handler: function() {
						$( this.element ).addClass( waypointVisible );
						this.destroy();
					},
					offset: '90%'
				} );
			} );
		} else {
			$waypoint.addClass( waypointVisible );
		}
	} );
} )( jQuery, window );
