// page init
jQuery(function(){
	initSameHeight();
	initSlider();
	initCustomHover();
});

// initCustomHover
function initCustomHover() {
	var speed = 200;
	var win = jQuery(window);
	var body = jQuery('body');
	jQuery('.council-area').each(function(e) {
		var holder = jQuery(this);
		var items = holder.find('.post');
		var btnCouncil = holder.find('.btn-council');
		var marginLeft = parseInt(btnCouncil.css('marginLeft'));
		var isTouchDevice = ('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch;
		var isWinPhoneDevice = navigator.msPointerEnabled && /MSIE 10.*Touch/.test(navigator.userAgent);

		win.on('load resize', function(e) {
			marginLeft = parseInt(btnCouncil.css('marginLeft'));
		});
		if( isTouchDevice ){
			items.on({
				'touchstart' : function(e) {
					if (win.width()>767) {
						e.preventDefault();
						var that = jQuery(this);
						var i;
						var img =  that.find('.image-holder');
						if (that.index() == 0) {
							items.eq(1).stop().animate({opacity: 0.4}, speed);
							btnCouncil.stop().animate({'marginLeft': marginLeft+20},speed);
							items.eq(0).animate({opacity: 1}, speed);
						} else {
							btnCouncil.stop().animate({'marginLeft': marginLeft-20},speed);
							items.eq(0).stop().animate({opacity: 0.4}, speed);
							items.eq(1).animate({opacity: 1}, speed);
						}
					}
				}
			});
			body.on('touchstart', function(event) {
				var target = jQuery(event.target);
				if (!target.is(items) && target.closest(items).length == 0) {
					var that = jQuery(this);
					items.stop().animate({opacity: 1}, speed);
					var img =  that.find('.image-holder');
					btnCouncil.stop().animate({'marginLeft': marginLeft},speed, function() {
						btnCouncil.css({'marginLeft': ''});
					});
				}
			});
		} else {
			items.on({
				'mouseenter' : function(e) {
					if (win.width()>767) {
						e.preventDefault();
						var that = jQuery(this);
						var i;
						var img =  that.find('.image-holder');
						if (that.index() == 0) {
							items.eq(1).stop().animate({opacity: 0.4}, speed);
							btnCouncil.stop().animate({'marginLeft': marginLeft+20},speed);
						} else {
							btnCouncil.stop().animate({'marginLeft': marginLeft-20},speed);
							items.eq(0).stop().animate({opacity: 0.4}, speed);
						}
					}
				},
				'mouseleave': function(e) {
					if (win.width()>767) {
						e.preventDefault();
						var that = jQuery(this);
						items.stop().animate({opacity: 1}, speed);
						var img =  that.find('.image-holder');
						btnCouncil.stop().animate({'marginLeft': marginLeft},speed, function() {
							btnCouncil.css({'marginLeft': ''});
						});
					}
				}
			});

		}
	});

}

// flexslider
function initSlider() {
	jQuery('.flexslider').flexslider({
		animation: "fade"
	});
}

// align blocks height
function initSameHeight() {
	jQuery('div.feature').sameHeight({
		elements: '.post',
		flexible: true,
		multiLine: true
	});
	jQuery('.council-area .post').sameHeight({
		elements: '.height',
		flexible: true,
		multiLine: true
	});
}

/*
 * jQuery SameHeight plugin
 */
;(function($){
	$.fn.sameHeight = function(opt) {
		var options = $.extend({
			skipClass: 'same-height-ignore',
			leftEdgeClass: 'same-height-left',
			rightEdgeClass: 'same-height-right',
			elements: '>*',
			flexible: false,
			multiLine: false,
			useMinHeight: false,
			biggestHeight: false
		},opt);
		return this.each(function(){
			var holder = $(this), postResizeTimer, ignoreResize;
			var elements = holder.find(options.elements).not('.' + options.skipClass);
			if(!elements.length) return;

			// resize handler
			function doResize() {
				elements.css(options.useMinHeight && supportMinHeight ? 'minHeight' : 'height', '');
				if(options.multiLine) {
					// resize elements row by row
					resizeElementsByRows(elements, options);
				} else {
					// resize elements by holder
					resizeElements(elements, holder, options);
				}
			}
			doResize();

			// handle flexible layout / font resize
			var delayedResizeHandler = function() {
				if(!ignoreResize) {
					ignoreResize = true;
					doResize();
					clearTimeout(postResizeTimer);
					postResizeTimer = setTimeout(function() {
						doResize();
						setTimeout(function(){
							ignoreResize = false;
						}, 10);
					}, 100);
				}
			};

			// handle flexible/responsive layout
			if(options.flexible) {
				$(window).bind('resize orientationchange fontresize', delayedResizeHandler);
			}

			// handle complete page load including images and fonts
			$(window).bind('load', delayedResizeHandler);
		});
	};

	// detect css min-height support
	var supportMinHeight = typeof document.documentElement.style.maxHeight !== 'undefined';

	// get elements by rows
	function resizeElementsByRows(boxes, options) {
		var currentRow = $(), maxHeight, maxCalcHeight = 0, firstOffset = boxes.eq(0).offset().top;
		boxes.each(function(ind){
			var curItem = $(this);
			if(curItem.offset().top === firstOffset) {
				currentRow = currentRow.add(this);
			} else {
				maxHeight = getMaxHeight(currentRow);
				maxCalcHeight = Math.max(maxCalcHeight, resizeElements(currentRow, maxHeight, options));
				currentRow = curItem;
				firstOffset = curItem.offset().top;
			}
		});
		if(currentRow.length) {
			maxHeight = getMaxHeight(currentRow);
			maxCalcHeight = Math.max(maxCalcHeight, resizeElements(currentRow, maxHeight, options));
		}
		if(options.biggestHeight) {
			boxes.css(options.useMinHeight && supportMinHeight ? 'minHeight' : 'height', maxCalcHeight);
		}
	}

	// calculate max element height
	function getMaxHeight(boxes) {
		var maxHeight = 0;
		boxes.each(function(){
			maxHeight = Math.max(maxHeight, $(this).outerHeight());
		});
		return maxHeight;
	}

	// resize helper function
	function resizeElements(boxes, parent, options) {
		var calcHeight;
		var parentHeight = typeof parent === 'number' ? parent : parent.height();
		boxes.removeClass(options.leftEdgeClass).removeClass(options.rightEdgeClass).each(function(i){
			var element = $(this);
			var depthDiffHeight = 0;
			var isBorderBox = element.css('boxSizing') === 'border-box' || element.css('-moz-box-sizing') === 'border-box' || '-webkit-box-sizing' === 'border-box';

			if(typeof parent !== 'number') {
				element.parents().each(function(){
					var tmpParent = $(this);
					if(parent.is(this)) {
						return false;
					} else {
						depthDiffHeight += tmpParent.outerHeight() - tmpParent.height();
					}
				});
			}
			calcHeight = parentHeight - depthDiffHeight;
			calcHeight -= isBorderBox ? 0 : element.outerHeight() - element.height();

			if(calcHeight > 0) {
				element.css(options.useMinHeight && supportMinHeight ? 'minHeight' : 'height', calcHeight);
			}
		});
		boxes.filter(':first').addClass(options.leftEdgeClass);
		boxes.filter(':last').addClass(options.rightEdgeClass);
		return calcHeight;
	}
}(jQuery));

/*
 * jQuery FontResize Event
 */
jQuery.onFontResize = (function($) {
	$(function() {
		var randomID = 'font-resize-frame-' + Math.floor(Math.random() * 1000);
		var resizeFrame = $('<iframe>').attr('id', randomID).addClass('font-resize-helper');

		// required styles
		resizeFrame.css({
			width: '100em',
			height: '10px',
			position: 'absolute',
			borderWidth: 0,
			top: '-9999px',
			left: '-9999px'
		}).appendTo('body');

		// use native IE resize event if possible
		if (window.attachEvent && !window.addEventListener) {
			resizeFrame.bind('resize', function () {
				$.onFontResize.trigger(resizeFrame[0].offsetWidth / 100);
			});
		}
		// use script inside the iframe to detect resize for other browsers
		else {
			var doc = resizeFrame[0].contentWindow.document;
			doc.open();
			doc.write('<scri' + 'pt>window.onload = function(){var em = parent.jQuery("#' + randomID + '")[0];window.onresize = function(){if(parent.jQuery.onFontResize){parent.jQuery.onFontResize.trigger(em.offsetWidth / 100);}}};</scri' + 'pt>');
			doc.close();
		}
		jQuery.onFontResize.initialSize = resizeFrame[0].offsetWidth / 100;
	});
	return {
		// public method, so it can be called from within the iframe
		trigger: function (em) {
			$(window).trigger("fontresize", [em]);
		}
	};
}(jQuery));
